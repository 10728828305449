<template>
  <div>
    <title-header :title="title"></title-header>
    <v-row class="mb-2">
      <v-col>
        <v-file-input
          label="Archivo para importar usuarios"
          truncate-length="15"
          show-size
          @change="handleFileUpload"
        ></v-file-input>
      </v-col>
    </v-row>
    <v-data-table :headers="headers" :items="content" class="elevation-1 pl-10 pr-10">
      <!-- <template v-slot:item.roles="{ item }">
        {{ item.roles.length > 1 ? 'Multiples' :
          item.roles.length === 0 ? 'N/A' : item.roles[0].name }}
      </template>

      <template v-slot:item.groups="{ item }">
        {{ item.groups.length > 1 ? 'Multiples' :
          item.groups.length === 0 ? 'N/A' : item.groups[0].name }}
      </template> -->

    </v-data-table>
  </div>
</template>
<script>
import TitleHeader from '@/components/app/title-header/title-header'
import Papa from 'papaparse'
// import { VueCsvImport } from 'vue-csv-import'

export default {
  name: 'UserImport',
  props: {},
  data() {
    return {
      title: 'Importar Usuarios',
      parseCsv: {},
      file: '',
      content: [],
      parsed: false,
      headersFile: ['nombre', 'correo'],
      headers: [
        { text: 'Nombre', value: 'nombre' },
        { text: 'Correo', value: 'correo' },
        { text: 'Rol', value: 'roles' },
        { text: 'Grupo', value: 'groups' },
        { text: 'Estatus', value: 'isActive' },
        { text: '', value: 'buttons', width: 265 }
      ]
    }
  },
  components: {
    TitleHeader
    // VueCsvImport
  },
  methods: {
    handleFileUpload(file) {
      this.file = file
      this.parseFile()
    },
    parseFile() {
      Papa.parse(this.file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          if (JSON.stringify(results.meta.fields) === JSON.stringify(this.headersFile)) {
            this.content = results.data
            this.parsed = true
          } else {
            alert('Formato del archivo invalido')
          }
          // /* eslint-disable */{console.log(...oo_oo(`892dfc6c_0`,results))}
        }.bind(this)
      })
    }
  }
}
</script>
